
@font-face {
  font-family: 'bold';
  src: local('Inter-Bold'), url(./assets/font/Inter/Inter-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'light';
  src: local('Inter-Light'), url(./assets/font/Inter/Inter-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'medium';
  src: local('Inter-Medium'), url(./assets/font/Inter/Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'regular';
  src: local('Inter-Regular'), url(./assets/font/Inter/Inter-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'thin';
  src: local('Inter-Thin'), url(./assets/font/Inter/Inter-Thin.ttf) format('truetype');
}
